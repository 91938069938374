<template>
    <div :class="[$style.ThePagination]">
        <IconsFilledArrow
            :class="[$style.icon, {[$style._disabled]: isInFirstPage}]"
            @click="onClickPreviousPage"
        />
        <div :class="$style.items">
            <div
                v-for="page in pages"
                :key="page.name"
                :class="[$style.item, {[$style._active]: page.name === currentPage}]"
                @click="onClickPage(page.name)"
            >
                {{ page.name }}
            </div>
        </div>
        <IconsFilledArrow
            :class="[$style.icon, $style._right, {[$style._disabled]: isInLastPage}]"
            @click="onClickNextPage"
        />
    </div>
</template>

<script setup>
const props = defineProps({
    maxVisibleButtons: {
        type: Number,
        required: false,
        default: 3,
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    perPage: {
        type: Number,
        default: 1,
    },
    currentPage: {
        type: Number,
        default: 1,
    },
});

const emit = defineEmits(['page-changed']);

const startPage = computed(() => {
    // When on the first page
    if (props.currentPage === 1) {
        return 1;
    }

    // When on the last page
    if (props.currentPage === props.totalPages) {
        return props.totalPages + 1 - props.maxVisibleButtons;
    }

    // When inbetween
    return props.currentPage - 1;
});

const pages = computed(() => {
    const range = [];

    for (
        let i = startPage.value;
        i <= Math.min(startPage.value + props.maxVisibleButtons - 1, props.totalPages);
        i++
    ) {
        if (i > 0) {
            range.push({
                name: i,
                isDisabled: i === props.currentPage,
            });
        }
    }
    return range;
});

const isInFirstPage = computed(() => props.currentPage === 1);
const isInLastPage = computed(() => props.currentPage === props.totalPages);
function onClickPage(page) {
    emit('page-changed', page);
}
function onClickPreviousPage() {
    emit('page-changed', props.currentPage - 1);
}
function onClickNextPage() {
    emit('page-changed', props.currentPage + 1);
}
</script>

<style lang="scss" module>
.ThePagination {
    display: flex;
    align-items: center;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    transition: color $base-transition;
    margin-right: 1.6rem;
    transform: rotate(90deg);

    &._right {
        transform: rotate(-90deg);
        margin-left: 1.6rem;
        margin-right: 0;
    }

    &:hover {
        color: $brand-green;
    }

    &._disabled {
        cursor: default;
        color: #8E8EA1;
        pointer-events: none;
    }
}

.items {
    display: flex;
    justify-content: space-between;
    gap: 1.6rem;
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.1rem;
    height: 5.1rem;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    border-radius: 50%;
    border: 2px solid #8E8EA1;
    cursor: pointer;
    transition: all $base-transition;

    &:hover {
        color: $brand-green;
        border-color: $brand-green;
    }

    &._active {
        color: $body-bg;
        background: linear-gradient(106.3deg, #B5D841 29.93%, #D7FF53 73.93%);
        box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.14);
        border-color: $brand-green;
    }
}
</style>
